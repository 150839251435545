import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Stage, Layer, Rect, Text, Image, Group } from "react-konva";
import "./Canvas.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";
import bin_icon from "../../ressources/bin_icon_inverted.png";

const Canvas = forwardRef(({ imgSrc, option, onZoom, scale, positionX, positionY }, ref) => {
	const [shapesCount, setShapesCount] = useState(0);
	const [isDrawing, setIsDrawing] = useState(false);
	const [rectangles, setRectangles] = useState([]);
	const [image, setImage] = useState(null);
	const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
	const stageRef = useRef(null);
	const transformWrapperRef = useRef(null);
	const [startPos, setStartPos] = useState({ x: 0, y: 0 });
	const [currentRect, setCurrentRect] = useState(null);
	const [transformState, setTransformState] = useState({
		scale: scale || 1,
		positionX: positionX || 0,
		positionY: positionY || 0
	});
	const imageRef = useRef(null);
	const [binIcon, setBinIcon] = useState(null);

	const getDeleteButtonSize = () => {
		const baseSize = 30;
		const scaledSize = baseSize / transformState.scale; // Adjust size based on zoom
		return {
			size: scaledSize,
			offset: scaledSize / 2 // half of size for positioning
		};
	};

	useEffect(() => {
		const img = new window.Image();
		img.crossOrigin = "Anonymous";

		img.onload = () => {
			if (imageRef.current) {
				imageRef.current.image(img);
				imageRef.current.getLayer().batchDraw();
			} else {
				setImage(img);
				setStageSize({
					width: img.width,
					height: img.height
				});
			}

			if (transformWrapperRef.current) {
				if (transformState.scale !== 1) {
					transformWrapperRef.current.setTransform(
						transformState.positionX,
						transformState.positionY,
						transformState.scale
					);
				} else {
					const controlsSection = document.getElementsByClassName("controls-section")[0];
					const canvasWidth = window.innerWidth / 2 - (controlsSection?.clientWidth ?? 0);
					const canvasHeight = window.innerHeight;
					const scaleX = canvasWidth / img.width;
					const scaleY = canvasHeight / img.height;
					const initialScale = Math.min(scaleX, scaleY);

					const offsetX = (canvasWidth - img.width * initialScale) / 2;
					const offsetY = (canvasHeight - img.height * initialScale) / 2;

					setTransformState({
						scale: initialScale,
						positionX: offsetX,
						positionY: offsetY
					});

					if (transformWrapperRef.current) {
						transformWrapperRef.current.setTransform(offsetX, offsetY, initialScale);
					}
				}
			}
		};

		img.src = imgSrc;
	}, [imgSrc]);

	useEffect(() => {
		if (!onZoom || !transformWrapperRef.current) return;

		const zoomControls = {
			zoomIn: () => transformWrapperRef.current.zoomIn(),
			zoomOut: () => transformWrapperRef.current.zoomOut()
		};

		onZoom(zoomControls);
	}, []);

	useEffect(() => {
		const img = new window.Image();
		img.src = bin_icon;
		img.onload = () => {
			setBinIcon(img);
		};
	}, []);

	useEffect(() => {
		if (scale !== undefined && positionX !== undefined && positionY !== undefined) {
			setTransformState({
				scale,
				positionX,
				positionY
			});

			if (transformWrapperRef.current) {
				transformWrapperRef.current.setTransform(positionX, positionY, scale);
			}
		}
	}, [scale, positionX, positionY]);

	const handleMouseDown = (e) => {
		if (option !== "rect" && option !== "barcodeRect") return;

		setIsDrawing(true);
		const pos = e.target.getStage().getPointerPosition();
		setStartPos({ x: pos.x, y: pos.y });

		const newRect = {
			x: pos.x,
			y: pos.y,
			width: 0,
			height: 0,
			fill: option === "rect" ? "rgba(211, 211, 211, 0.5)" : "rgba(162, 84, 158, 0.3)",
			stroke: option === "rect" ? "#A9A9A9" : "purple",
			strokeWidth: 4,
			id: `rect_${shapesCount + 1}`,
			isBarcode: option === "barcodeRect"
		};
		setCurrentRect(newRect);
	};

	const handleMouseMove = (e) => {
		if (!isDrawing) return;

		const stage = e.target.getStage();
		const pos = stage.getPointerPosition();

		const newRect = {
			...currentRect,
			x: Math.min(startPos.x, pos.x),
			y: Math.min(startPos.y, pos.y),
			width: Math.abs(pos.x - startPos.x),
			height: Math.abs(pos.y - startPos.y),
		};

		setCurrentRect(newRect);
	};

	const handleMouseUp = () => {
		if (!isDrawing) return;
		setIsDrawing(false);

		if (currentRect && (currentRect.width > 0 || currentRect.height > 0)) {
			currentRect.scale = transformState.scale;
			setRectangles([...rectangles, currentRect]);
			if (option === "rect") {
				setShapesCount(shapesCount + 1);
			}
		}
		setCurrentRect(null);
	};

	const handleClear = () => {
		setRectangles([]);
		setShapesCount(0);
	};

	const handleDeleteRect = (index) => {
		const newRectangles = rectangles.filter((_, i) => i !== index);
		setRectangles(newRectangles);
		if (!rectangles[index].isBarcode) {
			setShapesCount(shapesCount - 1);
		}
	};

	useImperativeHandle(ref, () => ({
		handleClear,
		getRectCoordinates: () => {
			return rectangles.map(rect => ({
				left: rect.x,
				top: rect.y,
				width: rect.width,
				height: rect.height,
				isBarcode: rect.isBarcode || false,
				scale: rect.scale,
			}));
		},
		getCanvasDimensions: () => ({
			width: stageSize.width,
			height: stageSize.height,
		}),
	}));

	return (
		<div className="imageEditor">
			<div className="transform-wrapper-container">
				<TransformWrapper
					ref={transformWrapperRef}
					limitToBounds={false}
					centerZoomedOut={false}
					initialScale={transformState.scale}
					minScale={0.1}
					maxScale={6}
					disabled={isDrawing}
					onTransformed={(e) => {
						setTransformState({
							scale: e.state.scale,
							positionX: e.state.positionX,
							positionY: e.state.positionY
						});
					}}
				>
					<TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
						<Stage
							className="canvas-container"
							ref={stageRef}
							width={stageSize.width}
							height={stageSize.height}
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
						>
							<Layer>
								{image && (
									<Image
										ref={imageRef}
										image={image}
										width={stageSize.width}
										height={stageSize.height}
									/>
								)}
								{rectangles.map((rect, i) => (
									<React.Fragment key={`canvas_fragment_${i}`}>
										<Group>
											<Rect {...rect} />
											{!rect.isBarcode && (
												<Text
													text={`${i + 1}`}
													x={rect.x}
													y={rect.y}
													fontSize={rect.height / 2}
													width={rect.width}
													height={rect.height}
													fill="black"
													align="center"
													verticalAlign="middle"
													fontFamily="Chanel M"
												/>
											)}
											<Group
												x={rect.x + rect.width - getDeleteButtonSize().offset}
												y={rect.y - getDeleteButtonSize().offset}
												onClick={() => handleDeleteRect(i)}
												onTap={() => handleDeleteRect(i)}
											>
												<Rect
													width={getDeleteButtonSize().size}
													height={getDeleteButtonSize().size}
													fill="red"
													cornerRadius={9999}
												/>
												{binIcon && (
													<Image
														image={binIcon}
														width={getDeleteButtonSize().size * 0.75}
														height={getDeleteButtonSize().size * 0.75}
														x={getDeleteButtonSize().size * 0.125}
														y={getDeleteButtonSize().size * 0.125}
													/>
												)}
											</Group>
										</Group>
									</React.Fragment>
								))}
								{currentRect && <Rect {...currentRect} />}
							</Layer>
						</Stage>
					</TransformComponent>
				</TransformWrapper>
			</div>
		</div>
	);
});

Canvas.propTypes = {
	imgSrc: PropTypes.string.isRequired,
	option: PropTypes.string.isRequired,
	onZoom: PropTypes.func,
	onClear: PropTypes.func,
	scale: PropTypes.number,
	positionX: PropTypes.number,
	positionY: PropTypes.number
};

Canvas.displayName = "Canvas";
export default Canvas;
