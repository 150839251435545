// SharedToolbar.jsx
import React from "react";
import PropTypes from "prop-types";
import select_zone_icon from "../../ressources/select_zone_icon.png";
import select_barcode_icon from "../../ressources/select_barcode_icon.png";
import reset_icon from "../../ressources/reset_icon.png";
import "./SharedToolbar.css";

const SharedToolbar = ({
	option,
	onOptionChange,
	onClear
}) => {
	return (
		<div className="toolbar">
			<button
				className={option === "rect" ? "btn active" : "btn"}
				onClick={() => onOptionChange("rect")}
			>
				<img src={select_zone_icon} alt="Selectionner une zone" />
			</button>

			<button
				className={option === "barcodeRect" ? "btn active" : "btn"}
				onClick={() => onOptionChange("barcodeRect")}
			>
				<img src={select_barcode_icon} alt="Selectionner un code barre" />
			</button>

			<button className="btn" onClick={onClear}>
				<img src={reset_icon} alt="Reinitialiser" />
			</button>
		</div>
	);
};

SharedToolbar.propTypes = {
	option: PropTypes.string.isRequired,
	onOptionChange: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired
};

export default SharedToolbar;